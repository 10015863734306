import { PUBLIC_IMAGE_URL } from '../../../utils';

export const SORT_LIST = [
  {
    label: '综合排序',
    value: '',
  },
  {
    label: '上新',
    value: 'putAwayTime',
  },
  {
    label: '销量',
    value: 'salesNum',
  },
  {
    label: '价格',
    value: 'productPrice',
  },
];

export const FILTER_LIST = [
  {
    label: '合作档口',
    value: 'cooperateVendor',
    needLogin: true,
  },
  {
    label: '隐藏已铺货的款',
    value: 'shelve',
    needLogin: true,
  },
  {
    label: '可退货',
    value: 'returnable',
    needLogin: false,
  },
  {
    label: '视频商品',
    value: 'video',
    needLogin: false,
  },
];

export const PRICE_IMG_MAP = {
  '': `${PUBLIC_IMAGE_URL}normalPrice.png`,
  desc: `${PUBLIC_IMAGE_URL}descPrice.png`,
  asc: `${PUBLIC_IMAGE_URL}ascPrice.png`,
};

export const PRICE_SORD = {
  '': 'asc',
  asc: 'desc',
  desc: '',
};

export const INPUTNUMBER_PROPS = {
  min: 0,
  precision: 2,
  style: {
    width: 55,
    height: 20,
  },
};

export const PARAMS = {
  page: 1,
  pageSize: 50,
};
