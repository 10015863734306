/**
 * 搜索结果页排序
 */
import { Checkbox, InputNumber } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { FILTER_LIST, INPUTNUMBER_PROPS, PRICE_IMG_MAP, SORT_LIST } from './constant';
import styles from './index.less';
import type Store from './store';

// 搜索结果页-综合排序面板
export const SordPanel = observer((props: { store: Store; currentSord: string; loginStatus: boolean; }) => {
  const { store: { priceType, gotoSord, showBtn, btnVisible, minProductPrice, maxProductPrice, confirm, cancel, changeNum, changeCheckbox }, currentSord, loginStatus } = props;
  const FILTER_LIST_SHOW = loginStatus ? FILTER_LIST : FILTER_LIST.filter((v) => !v.needLogin);
  return (
    <>
      <div className={styles.searchResultSordPanelWrap}>
        {
          SORT_LIST.map((item) => {
            const { label, value } = item;
            return (
              <div
                className={`${styles.sordItemWrap} ${currentSord === value ? styles.itemSelect : ''}`}
                key={value}
                onClick={() => {
                  gotoSord(value, priceType);
                }}
              >
                {label}
                {value === 'productPrice' && (
                  <img
                    alt=""
                    className={styles.priceImg}
                    src={PRICE_IMG_MAP[priceType]}
                  />
                )}
              </div>
            );
          })
        }

        <div className={styles.priceSectionWrap}>
          <InputNumber
            {...INPUTNUMBER_PROPS}
            onChange={(value) => {
              changeNum(value, 'minProductPrice');
            }}
            onFocus={showBtn}
            placeholder="最低价"
            value={minProductPrice}
          />
          {' '}
          -
          {' '}
          <InputNumber
            {...INPUTNUMBER_PROPS}
            onChange={(value) => {
              changeNum(value, 'maxProductPrice');
            }}
            onFocus={showBtn}
            placeholder="最高价"
            value={maxProductPrice}
          />
          {
            btnVisible && (
              <>
                <span
                  className={`${styles.btnCommon} ${styles.btnConfirm}`}
                  onClick={confirm}
                >
                  确定
                </span>
                <span
                  className={styles.btnCommon}
                  onClick={cancel}
                >
                  取消
                </span>
              </>
            )
          }
        </div>
      </div>
      <div className={styles.searchResulFilterPanelWrap}>
        {
          FILTER_LIST_SHOW.map((item) => {
            const { label, value } = item;
            return (
              <Checkbox
                key={value}
                onChange={(e) => {
                  changeCheckbox(e, value);
                }}
              >
                {label}
              </Checkbox>
            );
          })
        }
      </div>
    </>
  );
});
