import { Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { DynamicProductCard, VendorRelated } from '../components';
import styles from './index.less';
import type { Store } from './store';

@observer
export default class StallResult extends React.Component<{ store: Store; }> {
  constructor(props) {
    super(props);
  }

  render(): JSX.Element {
    const { dataList, headerStore: { gotoPage }} = this.props.store;
    return (
      <div
        className={`${styles.stallContentWrap} ${dataList.length === 0 ? styles.stallContentWrapEmpty : ''}`}
      >
        <Row>
          {
            dataList.map((item, index) => {
              const { id, shopName, mainCategoryName, newPushGoodsNum, goodsList, discountPer } = item;
              return (
                <VendorRelated
                  discountPercent={discountPer}
                  goodsPutOnNumWeek={newPushGoodsNum}
                  key={id}
                  listContent={(
                    <StallGoodsInfo
                      goodsList={goodsList}
                      gotoPage={gotoPage}
                    />
                  )}
                  mainCategoryName={mainCategoryName}
                  onClickVendorName={() => {
                    gotoPage(`/egenie-ts-vogue/stallDetail/index?vendorId=${id}`);
                  }}
                  showNameEnterIcon
                  type="detail"
                  vendorId={id}
                  vendorName={shopName}
                  width={1148}
                />
              );
            })
          }
        </Row>
      </div>

    );
  }
}

const StallGoodsInfo = observer((props) => {
  const { goodsList, gotoPage } = props;
  if (goodsList?.length === 0) {
    return null;
  } else {
    return (
      <Row gutter={[
        12,
        12,
      ]}
      >
        {
          goodsList?.map((item, index) => {
            const { activityPrice, discountPrice, goodsId, mainPicUrl, visibleType, wholePrice } = item;
            return (
              <Col
                key={goodsId}
              >
                <DynamicProductCard
                  activityPrice={activityPrice}
                  discountPrice={discountPrice}
                  goodsId={goodsId}
                  mainPicUrl={mainPicUrl}
                  onClick={() => {
                    gotoPage(`/egenie-ts-vogue/goodsDetail/index?goodsId=${goodsId}`);
                  }}
                  price={wholePrice}
                  visibleType={visibleType}
                />
              </Col>
            );
          })
        }
      </Row>
    );
  }
});

