import type { FilterItemProps } from '../components/filter/interface';

export const FILTER_ITEMS = (store): FilterItemProps[] => {
  return [
    {
      type: 'select',
      label: '',
      field: 'sidx',
      data: [
        {
          label: '综合排序',
          value: '',
        },
        {
          label: '上新',
          value: 'putAwayTime',
        },
        {
          label: '销量',
          value: 'salesNum',
        },
        {
          label: '价格由低到高',
          value: 'priceLow',
        },
        {
          label: '价格由高到低',
          value: 'priceHigh',
        },
      ],
      value: '',
    },
    {
      type: 'treeSelect',
      label: '',
      field: 'ftCategoryIds',
      showSearch: true,
      allowClear: true,
      placeholder: '全部品类',
    },
    {
      type: 'select',
      mode: 'multiple',
      showSearch: true,
      label: '',
      field: 'style',
      allowClear: true,
      placeholder: '全部风格',
    },
    {
      type: 'select',
      mode: 'multiple',
      showSearch: true,
      label: '',
      field: 'marketIds',
      allowClear: true,
      placeholder: '全部市场',
    },
    {
      type: 'select',
      mode: 'multiple',
      showSearch: true,
      label: '',
      field: 'applicableChannel',
      allowClear: true,
      placeholder: '主销渠道',
    },
    {
      type: 'select',
      mode: 'multiple',
      showSearch: true,
      label: '',
      field: 'applicableSeason',
      allowClear: true,
      placeholder: '适用季节',
    },
    {
      type: 'select',
      mode: 'multiple',
      showSearch: true,
      label: '',
      field: 'applicableGender',
      allowClear: true,
      placeholder: '适用性别',
      collapseItem: true,
    },
    {
      type: 'select',
      mode: 'multiple',
      showSearch: true,
      label: '',
      field: 'applicableAge',
      allowClear: true,
      placeholder: '适用年龄',
      collapseItem: true,
    },
    {
      type: 'inputNumberGroup',
      label: '',
      field: 'prices',
      minLength: 1,
      maxLength: 5,
      prefix: '￥',
      collapseItem: true,
    },

  ];
};

export const TAGS_MAP = [
  'style',
  'applicableAge',
  'applicableGender',
  'applicableChannel',
  'applicableSeason',
];

export const StallFilterItems = (store): FilterItemProps[] => {
  return [
    {
      type: 'select',
      label: '',
      field: 'sidx',
      data: [
        {
          label: '综合排序',
          value: '',
        },
        {
          label: '上新',
          value: 'putAwayTime',
        },
        {
          label: '销量',
          value: 'salesNum',
        },
      ],
      value: '',
    },
    {
      type: 'select',
      label: '',
      field: 'mainCategoryId',
      allowClear: true,
      placeholder: '全部类目',
    },
  ];
};
