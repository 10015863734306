import { observer } from 'mobx-react';
import type { ReactNode } from 'react';
import React, { Component } from 'react';
import { TagList } from '../../homePage/dynamicInfo';
import { PUBLIC_IMAGE_URL } from '../../../utils';
import { CountDownCom } from '../../components/countDownCom';
import styles from './index.less';

@observer
export class ActiveHeader extends Component<{ store?; }> {
  render(): ReactNode {
    const { countDownTime, activeDetail: { name, id, discount, beginTime, endTime, bgColor, pcBgUrl, discountEnable, tagList }} = this.props.store;
    const bgColors = bgColor?.split(',') || [];
    return (
      <div
        className={`${styles.activeHeaderWrap} ${pcBgUrl ? styles.pcBgUrlWrap : ''}`}
        style={{ background: pcBgUrl ? '#fff' : bgColors.length > 0 ? `linear-gradient(270deg, ${bgColors[1] || ''} 0%, ${bgColors[0] || ''} 100%)` : '#fff' }}
      >
        {
          !pcBgUrl ? (
            <>
              <img
                alt=""
                className={styles.activeHeaderBg}
                src={`${PUBLIC_IMAGE_URL}activeHeaderBg.png`}
              />
              <div className={styles.activeTime}>
                <img
                  alt=""
                  className={styles.activeTimeImg}
                  src={`${PUBLIC_IMAGE_URL}activeTimeDetailImg.png`}
                />
                <span className={styles.activeTimeTxt}>
                  活动时间
                </span>
                {beginTime}
                -
                {endTime}
              </div>
              <div
                className={styles.activeName}
              >
                <span>
                  {name}
                </span>
              </div>
              <TagList
                bgColors={bgColors}
                className={styles.activeDiscount}
                tagList={tagList}
                tagStyle={styles.tagStyle}
              />
            </>
          ) : (
            <img
              alt=""
              className={styles.pcBgUrl}
              src={pcBgUrl}
              style={{ width: '100%' }}
            />
          )
        }
        <div className={styles.bottomWrap}>
          {Object.keys(countDownTime).length > 0 && (
            <div className={styles.countDownComWrap}>
              <CountDownCom
                countDownTime={countDownTime}
                pcFontColor={bgColors}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
