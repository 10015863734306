import { message } from 'antd';
import Compressor from 'compressorjs';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import heic2any from 'heic2any';
import { action, observable } from 'mobx';
import { api } from '../../../utils';
import { IMG_URL } from './constant';
import type { IOssConfig, IPolicy } from './interface';
import { singleUpload } from 'egenie-common';

export class ImageSearchStore {
  constructor(options) {
    // this.getOssConfig();
    this.parent = options.parent;
  }

  @observable public parent;

  @observable public searchName = '';

  @observable public loading = false;

  @observable public searchPic = '';

  @observable public showUploadPanel = true;

  // 阿里云配置信息
  @observable public ossConfig: IOssConfig = {
    accessKeyId: '',
    accessKeySecret: '',
    bucket: '',
    region: '',
    stsToken: '',
  };

  @observable public dir = ''; // 目录 上传图片用

  @observable public videoVisible = false;

  // 获取阿里云配置信息
  @action public getOssConfig = async() => {
    const res: BaseData<IPolicy> = await request({ url: api.getAliYunPolicy });
    const {
      accessKeyId,
      accessKeySecret,
      bucketName,
      region,
      dir,
      token,
    } = res.data;
    this.ossConfig = {
      accessKeyId,
      accessKeySecret,
      bucket: bucketName,
      region,
      stsToken: token,
    };
    this.dir = dir;
  };

  @action public changeInput = (e: { target: { value: string; }; }): void => {
    this.searchName = e.target.value;
  };

  // 判断是否搜索结果页
  @action public getSearchResultPage = () => {
    return window.location.pathname === '/egenie-ts-vogue/searchResult/index';
  };

  @action public refreshPage = (url) => {
    window.location.assign(url);
  };

  @action public gotoPage = (url): void => {
    window.open(url);
  };

  @action public searchPro = (): void => {
    if (IMG_URL.test(this.searchName.split('?')[0])) {
      this.parent.searchImgUrl = this.searchName;
      this.parent.handleQuery({ page: 1 });
      this.toogleUploadPanel(false);
    } else {
      message.error('请输入正确的图片链接');
    }
  };

  @action public beforeUpload = (file: File): boolean => {
    this.checkPic(file) && this.uploadImg(file);
    return false;
  };

  // 图片格式、大小校验
  @action public checkPic = (file: File): boolean => {
    const mimeType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/heic';
    if (!mimeType) {
      this.loading = false;
      message.error('请上传jpg或png格式图片！');
      return false;
    }
    if (file.size > 30 * 1024 * 1024) {
      this.loading = false;
      message.error('图片文件大小超过限制');
      return false;
    }
    return true;
  };

  // 处理heic转jpg
  @action public uploadImg = (file: File): void => {
    this.loading = true;
    if (file.type !== 'image/heic') {
      new Compressor(file, {
        quality: 0.6,
        success: (result) => {
          this.uploadImgFn(result);
        },
      });
    } else {
      heic2any({
        blob: file, // 将heic转换成一个buffer数组的图片
        toType: 'image/jpg', // 要转化成具体的图片格式，可以是png/gif
        quality: 0.6, // 图片的质量，参数在0-1之间

      })
        .then((result) => {
          this.uploadImgFn(result);
        })
        .catch((error) => {
          // 手动改变heic，该插件不识别
          console.log(error);
          message.error('图片信息有误，请重新选择图片上传');
          this.loading = false;
        });
    }
  };

  // 上传图片
  @observable public uploadImgFn = (file): void => {
    this.loading = false;
    new Compressor(file, {
      quality: 0.6,
      success: async(result) => {
        console.log('Compressor', result);

        // const client = new OSS(this.ossConfig);
        // const name = `${Date.now()}_${file.name.replace(/,|，| |/g, '')}`;
        // const r1 = await client.put(`${this.dir}/${name}`, result);
        // const url = r1.url
        //   .replace('http://', 'https://')
        //   .replace('https://egenie-frontend.oss-cn-hangzhou.aliyuncs.com', 'https://front.ejingling.cn');
        const url = await singleUpload({ obsConfigId: 26 }, file);
        this.searchPic = url;
        if (this.parent.searchImg) {
          this.parent.searchImgUrl = url;
          this.parent.handleQuery({ page: 1 });
        } else {
          const gotourl = `/egenie-ts-vogue/searchResult/index?pageOrigin=searchImg&searchImg=true&searchImgUrl=${url}&searchTypeKey=style`;
          this.gotoPage(gotourl);
        }
        this.toogleUploadPanel(false);
      },
    });
  };

  @action public operateVideoModal = (boolean) => {
    this.videoVisible = boolean;
  };

  @action public toogleUploadPanel = (visible) => {
    this.showUploadPanel = visible;
  };
}
