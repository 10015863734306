import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { api, PUBLIC_IMAGE_URL } from '../../../utils';
import type { IBusinessDistrict } from '../../homePage/interface';
import styles from './index.less';
import type { Category, FilterData, IStyleDict, Market, Tag, TagGroup } from './interface';

// 搜索结果页-款式筛选组件
interface Props {
  ftCategoryIds?: number[];
  parentCategoryId?: number;
  clothesStyle?: string;
  cityId?: number;
  marketIds?: number[];
  onSearch?: (filterData?: FilterData) => void; // 查询回调，filterData为传参
}

const GoodsFilter = (props: Props) => {
  const [
    showAll,
    setShowAll,
  ] = useState(true); // 组件收缩/展开

  const [
    selectedTags,
    setSelectedTags,
  ] = useState([]); // 已选条件tags

  const [
    tagGroup,
    setTagGroup,
  ] = useState([]); // 筛选项

  // 初始化数据
  const initOptions = async(): Promise<void> => {
    let groupOptions = await getTagGroup();
    groupOptions = groupOptions.filter((v) => v.tagGroupId !== 10001);

    // const marketOption = await getMarket();
    const marketOption = await getCityAndMarket();
    const categoryOption = await getCategory();
    const styleDict = await getStyleDict();
    const {
      category,
      secondCategory,
    } = writeBack(categoryOption);
    const {
      city,
      market,
    } = writeBackCityAndMarket(marketOption);
    setTagGroup([
      city,
      market,
      category,
      secondCategory,
      ...groupOptions,
      styleDict,

    ]);
  };

  const writeBackCityAndMarket = (marketOption) => {
    let clothesStyleSelected = [];
    if (props.clothesStyle) {
      clothesStyleSelected = [
        {
          tagGroupId: 10001,
          tagId: props.clothesStyle,
          tagName: props.clothesStyle,
        },
      ];
    }
    const secondMarketList = {
      tagGroupId: 9528,
      tagGroupName: '市场',
      tags: [],
      multi: true,
      mode: 'single',
      tempSelectedTags: [],
    };
    if (!props.cityId) { // 搜索条件回显
      return {
        city: marketOption,
        market: secondMarketList,
      };
    }
    const city = marketOption.tags.find((item) => item.tagId === props.cityId);
    if (!city) {
      return {
        city: marketOption,
        market: secondMarketList,
      };
    }
    secondMarketList.tags = city.children;
    if (!props.marketIds || props.marketIds.length === 0) {
      // 处理只传入城市的搜索
      const marketList = secondMarketList.tags;
      const newSelectedTags = [
        ...selectedTags,
        ...clothesStyleSelected,
        {
          ...city,
          tagGroupId: 9527,
        },
        {
          marketList,
          tagGroupId: 9528,
        },
      ];
      setSelectedTags(newSelectedTags);

      const filterData = convertFilterData(newSelectedTags);
      props.onSearch && props.onSearch(filterData); // 立即触发搜索
      return {
        city,
        market: secondMarketList,
      };
    }

    const marketId = props.marketIds[0];
    const secondMarket = city.children.find((item) => item.tagId === marketId);
    if (secondMarket) {
      const newSelectedTags = [
        ...selectedTags,
        ...clothesStyleSelected,
        {
          ...city,
          tagGroupId: 9527,
        },
        {
          secondMarket,
          tagGroupId: 9528,
        },
      ];
      setSelectedTags(newSelectedTags);
      const filterData = convertFilterData(newSelectedTags);
      props.onSearch && props.onSearch(filterData); // 立即触发搜索
    }
    return {
      city: marketOption,
      market: secondMarketList,
    };
  };

  const writeBack = (categoryOption: TagGroup) => {
    let clothesStyleSelected = [];
    if (props.clothesStyle) {
      clothesStyleSelected = [
        {
          tagGroupId: 10001,
          tagId: props.clothesStyle,
          tagName: props.clothesStyle,
        },
      ];
    }
    const secondCategoryList = {
      tagGroupId: 3,
      tagGroupName: '细分类目',
      tags: [],
      multi: true,
      mode: 'single',
      tempSelectedTags: [],
    };

    if (!props.parentCategoryId) { // 搜索条件回显
      return {
        category: categoryOption,
        secondCategory: secondCategoryList,
      };
    }

    const category = categoryOption.tags.find((item) => item.tagId === props.parentCategoryId);
    if (!category) {
      return {
        category: categoryOption,
        secondCategory: secondCategoryList,
      };
    }
    secondCategoryList.tags = category.children;
    if (!props.ftCategoryIds || props.ftCategoryIds.length === 0) {
      // 处理只传入一级类目的搜索
      const ftCategoryIdsList = secondCategoryList.tags;
      const newSelectedTags = [
        ...selectedTags,
        ...clothesStyleSelected,
        {
          ...category,
          tagGroupId: 2,
        },
        {
          ftCategoryIdsList,
          tagGroupId: 3,
        },
      ];
      setSelectedTags(newSelectedTags);
      const filterData = convertFilterData(newSelectedTags);
      props.onSearch && props.onSearch(filterData); // 立即触发搜索
      return {
        category: categoryOption,
        secondCategory: secondCategoryList,
      };
    }

    const id = props.ftCategoryIds[0];
    const secondCategory = category.children.find((item) => item.tagId === id);
    if (secondCategory) {
      const newSelectedTags = [
        ...selectedTags,
        ...clothesStyleSelected,
        {
          ...category,
          tagGroupId: 2,
        },
        {
          ...secondCategory,
          tagGroupId: 3,
        },
      ];
      setSelectedTags(newSelectedTags);
      const filterData = convertFilterData(newSelectedTags);
      props.onSearch && props.onSearch(filterData); // 立即触发搜索
    }
    return {
      category: categoryOption,
      secondCategory: secondCategoryList,
    };
  };

  // 查询款式标签列表
  const getTagGroup = async(): Promise<TagGroup[]> => {
    const res: BaseData<TagGroup[]> = await request({ url: `${api.getTagGroup}?type=3` });
    return res.data?.filter((item) => item.tagGroupId !== 10003)
      .map((item) => { // 删除适用对象筛选项
        return {
          ...item,
          multi: true,
          mode: 'single',
          tempSelectedTags: [],
        };
      });
  };

  // 查询市场列表
  const getMarket = async(): Promise<TagGroup> => {
    const res: BaseData<Market[]> = await request({ url: api.getGoodsMarket });
    const list = res.data.map((item) => {
      return {
        tagId: item.id,
        tagName: item.name,
      };
    });
    return {
      tagGroupId: 1,
      tagGroupName: '市场',
      tags: list,
      multi: false,
      mode: 'single',
      tempSelectedTags: [],
    };
  };

  // 查询风格
  const getStyleDict = async(): Promise<TagGroup> => {
    const res: BaseData<IStyleDict[]> = await request({ url: api.getStyleDict });
    const list = res.data.map((item) => {
      return {
        tagId: item.name,
        tagName: item.name,
      };
    });
    return {
      tagGroupId: 10001,
      tagGroupName: '风格',
      tags: list,
      multi: true,
      mode: 'single',
      tempSelectedTags: [],
    };
  };

  // 搜索结果页直接展示第三级泪目
  const getAllThirdCategory = (secondCategory: Category[]): Category[] => {
    let arr = [];
    if (!secondCategory || secondCategory.length === 0) {
      return arr;
    }
    secondCategory.map((item) => {
      if (item.child) {
        arr = arr.concat(item.child);
      }
    });
    return arr;
  };

  // 查询前端分类，即类目
  const getCategory = async(): Promise<TagGroup> => {
    const res: BaseData<Category[]> = await request({ url: api.queryMoreCategory });
    const list = res.data.map((item) => {
      const thirdCategory = getAllThirdCategory(item?.child || []);
      return {
        tagId: item.id,
        tagName: item.ftCategoryName,
        children: thirdCategory?.map((tag) => {
          return {
            tagId: tag.id,
            tagName: tag.ftCategoryName,
          };
        }),
      };
    });
    return {
      tagGroupId: 2,
      tagGroupName: '类目',
      tags: list,
      multi: false,
    };
  };

  const getCityAndMarket = async(): Promise<TagGroup> => {
    const res: BaseData<IBusinessDistrict[]> = await request({ url: api.queryBusinessDistrict });
    const list = res.data.map((item) => {
      return {
        tagId: item.cityId,
        tagName: item.cityName,
        children: item.businessDistrictList?.map((tag) => {
          return {
            tagId: `${item.cityId}-${tag.id}`,
            tagName: tag.name,
          };
        }),
      };
    });
    return {
      tagGroupId: 9527,
      tagGroupName: '城市',
      tags: list,
      multi: false,
    };
  };

  useEffect(() => {
    initOptions();
  }, []);

  // 点击筛选项
  const handleClickTag = (tag: Tag, isInclude: boolean, group: TagGroup) => {
    if (isInclude) {
      let newList = selectedTags.filter((item) => item.tagId !== tag.tagId);
      if (group.tagGroupId === 2) {
        const index = tagGroup.findIndex((item) => item.tagGroupId === 3);
        tagGroup[index].tags = [];
        newList = newList.filter((item) => item.tagGroupId !== 3);
      }
      if (group.tagGroupId === 9527) {
        const index = tagGroup.findIndex((item) => item.tagGroupId === 9528);
        tagGroup[index].tags = [];
        newList = newList.filter((item) => item.tagGroupId !== 9528);
      }
      setSelectedTags(newList);
      if (group.mode === 'multi') { // 多选
        const index = tagGroup.findIndex((item) => item.tagGroupId === group.tagGroupId);
        if (index > -1) {
          const exist = tagGroup[index].tempSelectedTags.find((v) => v.tagId === tag.tagId);
          if (exist) {
            tagGroup[index].tempSelectedTags = tagGroup[index].tempSelectedTags.filter((v) => v.tagId !== tag.tagId);
          } else {
            tagGroup[index].tempSelectedTags.push({
              ...tag,
              tagGroupId: group.tagGroupId,
            });
          }

          setTagGroup([...tagGroup]);
        }
        return;
      } else {
        const filterData = convertFilterData(newList);
        props.onSearch && props.onSearch(filterData); // 单选立即触发搜索
        return;
      }
    }
    let newList = selectedTags;

    if (group.tagGroupId === 2) { // 一级前端分类
      const index = tagGroup.findIndex((item) => item.tagGroupId === 3);
      const children = group.tags.find((item) => item.tagId === tag.tagId)?.children;
      tagGroup[index].tags = children;
      newList = newList.filter((item) => item.tagGroupId !== 3);
    }
    if (group.tagGroupId === 9527) { // 一级前端分类
      const index = tagGroup.findIndex((item) => item.tagGroupId === 9528);
      const children = group.tags.find((item) => item.tagId === tag.tagId)?.children;
      tagGroup[index].tags = children;
      newList = newList.filter((item) => item.tagGroupId !== 9528);
    }
    if (group.mode === 'multi') { // 多选
      const index = tagGroup.findIndex((item) => item.tagGroupId === group.tagGroupId);
      if (index > -1) {
        tagGroup[index].tempSelectedTags.push({
          ...tag,
          tagGroupId: group.tagGroupId,
        });
        setTagGroup([...tagGroup]);
      }
    } else { // 单选
      newList = newList.filter((item) => item.tagGroupId !== group.tagGroupId);
      const newSelectedTags = [
        ...newList,
        {
          ...tag,
          tagGroupId: group.tagGroupId,
        },
      ];
      setSelectedTags(newSelectedTags);
      const filterData = convertFilterData(newSelectedTags);
      props.onSearch && props.onSearch(filterData); // 单选立即触发搜索
    }
  };

  // 删除已选条件
  const handleDeleteTag = (tagId: number) => {
    const newList = selectedTags.filter((item) => item.tagId !== tagId);
    setSelectedTags(newList);
    const filterData = convertFilterData(newList);
    props.onSearch && props.onSearch(filterData);
  };

  // 重置已选条件
  const handleReset = () => {
    tagGroup.forEach(((item) => {
      if (item.tagGroupId === 3) { // 清除二级类目
        item.tags = [];
      }
      item.mode = 'single';
      item.tempSelectedTags = [];
    }));
    setSelectedTags([]);
    props.onSearch && props.onSearch(convertFilterData([]));
  };

  // 多选、单选切换
  const handleChangeMode = (tagGroupId: number, isMulti: boolean) => {
    const index = tagGroup.findIndex((item) => item.tagGroupId === tagGroupId);
    const selected = selectedTags.filter((item) => item.tagGroupId === tagGroupId);
    if (index > -1) {
      tagGroup[index].mode = isMulti ? 'multi' : 'single';
      tagGroup[index].tempSelectedTags = isMulti ? selected : [];
    }
    setTagGroup([...tagGroup]);
    if (!isMulti) { // 取消多选，清空已选择的所有条件并查询
      const newList = selectedTags.filter((item) => item.tagGroupId !== tagGroupId);
      setSelectedTags(newList);
      const filterData = convertFilterData(newList);
      props.onSearch && props.onSearch(filterData);
    }
  };

  const getSelectedRes = (arr) => {
    return Array.from(new Set(arr));
  };

  // 多选:确认按钮点击事件
  const handleSearch = (tagGroupId: number) => {
    const index = tagGroup.findIndex((item) => item.tagGroupId === tagGroupId);
    if (index > -1) {
      const newSelectedTags = [
        ...selectedTags,
        ...tagGroup[index].tempSelectedTags,
      ];
      const arr = _.uniqBy(newSelectedTags, 'tagId');
      setSelectedTags(arr); // 去重
      tagGroup[index].tempSelectedTags = [];
      tagGroup[index].mode = 'single';
      const filterData = convertFilterData(newSelectedTags);
      props.onSearch && props.onSearch(filterData);
    }
  };

  // 筛选数据处理
  const convertFilterData = (tags: Tag[]): FilterData => {
    // const marketIds = tags.filter((item) => item.tagGroupId === 1)?.map((item) => item.tagId); // 市场
    const parentFtCategoryId = tags.find((item) => item.tagGroupId === 2)?.tagId; // 一级类目
    const ftCategoryIds = tags.filter((item) => item.tagGroupId === 3)
      ?.map((item) => item.tagId); // 二级分类
    const cityId = tags.find((item) => item.tagGroupId === 9527)?.tagId; // 城市
    const marketIds = tags.filter((item) => item.tagGroupId === 9528)
      ?.map((item) => item.tagId);
    const applicableSeason = tags.filter((item) => item.tagGroupId === 10017)
      ?.map((item) => item.tagId); // 适用季节
    const clothesStyle = tags.filter((item) => item.tagGroupId === 10001)
      ?.map((item) => item.tagId); // 风格
    const applicableAge = tags.filter((item) => item.tagGroupId === 100019)
      ?.map((item) => item.tagId); // 适用年龄
    const applicableGender = tags.filter((item) => item.tagGroupId === 10018)
      ?.map((item) => item.tagId); // 适用性别
    const applicableChannel = tags.filter((item) => item.tagGroupId === 10015)
      ?.map((item) => item.tagId); // 主销渠道
    const filterData = {
      // marketIds,
      cityId,
      marketIds: marketIds.map((v) => Number(`${v}`.split('-')[1])),
      applicableSeason: getSelectedRes(applicableSeason),
      clothesStyle,
      applicableAge: getSelectedRes(applicableAge),
      applicableGender: getSelectedRes(applicableGender),
      applicableChannel: getSelectedRes(applicableChannel),
      parentFtCategoryId,
      ftCategoryIds: ftCategoryIds[0] ? Array.from(new Set(ftCategoryIds)) : [],
    } as FilterData;

    return filterData;
  };
  return (
    <div className={styles.goodsFilter}>
      <div className={styles.header}>
        <span className={styles.label}>
          已选条件
        </span>
        <div className={styles.selectedTags}>
          {selectedTags.map((item) => {
            return item.tagName ? (
              <span
                className={styles.tagItem}
                key={item.tagId}
              >
                {item.tagName}
                <img
                  alt=""
                  className={styles.deleteIcon}
                  onClick={() => handleDeleteTag(item.tagId)}
                  src={`${PUBLIC_IMAGE_URL}delete.png`}
                />
              </span>
            ) : null;
          })}
          {selectedTags.length > 0 && (
            <span
              className={styles.resetBtn}
              onClick={handleReset}
            >
              重置
            </span>
          )}
        </div>
        <span
          className={styles.hiddenBtn}
          onClick={() => setShowAll(!showAll)}
        >
          隐藏筛选
          <img
            alt=""
            className={!showAll ? styles.arrowDown : ''}
            src={`${PUBLIC_IMAGE_URL}arrowUp.png`}
          />
        </span>
      </div>
      <div className={showAll ? `${styles.tagGroup} ${styles.tagGroupDown}` : `${styles.tagGroup} ${styles.tagGroupUp}`}>
        {tagGroup.map((item) => {
          return item.tags?.length > 0 ? (
            <div
              className={styles.tagGroupItem}
              key={item.tagGroupId}
            >
              <div className={styles.tagsWrapper}>
                <span className={styles.tagGroupName}>
                  {item.tagGroupName}
                </span>
                <div className={styles.tags}>
                  {item.tags.map((tag: Tag) => {
                    const index = selectedTags.findIndex((item) => item.tagId === tag.tagId);
                    const tagGroupIndex = selectedTags.findIndex((v) => v.tagGroupId === item.tagGroupId);
                    const tempIdx = item.tempSelectedTags?.findIndex((item) => item.tagId === tag.tagId);
                    return (
                      <span
                        className={((tagGroupIndex > -1 && index > -1) || tempIdx > -1) ? `${styles.tag} ${styles.selected}` : styles.tag}
                        key={tag.tagId}
                        onClick={() => handleClickTag(tag, (index > -1 || tempIdx > -1), item)}
                      >
                        {tag.tagName}
                      </span>
                    );
                  })}
                </div>
                {item.multi && (
                  <div
                    className={styles.multiBtn}
                    onClick={() => handleChangeMode(item.tagGroupId, true)}
                  >
                    <img
                      alt=""
                      className={styles.addIcon}
                      src={`${PUBLIC_IMAGE_URL}add.png`}
                    />
                    <span>
                      多选
                    </span>
                  </div>
                )}
              </div>
              {item.mode === 'multi' && (
                <div className={styles.btns}>
                  <div
                    className={styles.okBtn}
                    onClick={() => handleSearch(item.tagGroupId)}
                  >
                    确定
                  </div>
                  <div
                    className={styles.cancelBtn}
                    onClick={() => handleChangeMode(item.tagGroupId, false)}
                  >
                    取消
                  </div>
                </div>
              )}
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
};

export { GoodsFilter };
