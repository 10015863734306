/**
 * 顶部搜索组件，包括左边logo，中间搜索，右边视频简介
 * 滑动到一定距离，要吸顶
 */
import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { PUBLIC_IMAGE_URL, setLocationHref } from '../../utils';
import { Filter, PaginationCus } from '../components';
import EmptyStatus from '../components/empty';
import { Footer } from '../components/footer';
import { FooterLogo } from '../components/footerLogo';
import { GoodsFilter } from '../components/goodsFilter';
import { HeaderSearch } from '../components/headerSearch';
import { ImageSearch } from '../components/imageSearch';
import { Navigation } from '../components/navigation';
import { RightSider } from '../components/rightSider';
import { SordPanel } from '../components/sordPanel';
import commonStyles from '../homePage/common.less';
import { ActiveHeader } from './activeHeader';
import styles from './index.less';
import StallResult from './stallResult';
import { Store } from './store';
import StyleResult from './styleResult';

const store = new Store();
@observer
export default class ChooseSquareSearchResult extends React.Component {
  public componentWillMount() {
    setLocationHref();
    store.setLoading();
    const params = window.location.href.split('?')[1];
    const search = new URLSearchParams(params);
    const searchImg = search.get('searchImg') || false;
    const searchTypeKey = search.get('searchTypeKey') || false;
    const searchImgUrl = search.get('searchImgUrl') || '';
    const pageOrigin = search.get('pageOrigin') || 'search';
    const activeId = search.get('activeId') || '';
    const clothesStyle = search.get('clothesStyle') || '';
    const parentCategoryId = search.get('parentCategoryId') || '';
    const ftCategoryIds = search.get('ftCategoryIds') || '';
    const businessDistrictId = search.get('businessDistrictId') || '';
    const cityId = search.get('cityId') || '';

    store.setParams({
      searchTypeKey,
      searchImg,
      searchImgUrl,
      pageOrigin,
      activeId,
      clothesStyle,
      parentCategoryId,
      ftCategoryIds,
      businessDistrictId,
      cityId,
    });
  }

  render(): JSX.Element {
    const { ftCategoryIds, parentCategoryId, clothesStyle, loading, pageOrigin, dataList, searchImg, searchImgUrl, loginStatus, headerStore, headerStore: { searchTypeKey, searchName }, activeDetail, sordPanelStore, currentSord, onSearch, paginationStore, totalCount, totalPageCount, filterParams, stallFilterItems, imageSearchStore, rightSiderStore, cityId, marketIds } = store;
    return (
      <div
        className={styles.searchResultWrap}
        id="searchResultWrap"
      >
        <Spin spinning={loading}>
          <Navigation/>
          {
            pageOrigin !== 'active' ? (
              <div
                className={styles.mainWrap}
                style={{ borderBottom: !searchImg ? '1px solid #e2e2e5' : '' }}
              >
                {
                  !searchImg ? (
                    <HeaderSearch
                      searchImg={searchImg}
                      searchResult
                      showCategory={false}
                      showClothes={false}
                      showTab={false}
                      store={headerStore}
                      videoStyle={{ top: 0 }}
                    />
                  ) : (
                    <ImageSearch
                      searchImg={searchImg}
                      searchResult
                      showTab={false}
                      store={imageSearchStore}
                    />
                  )
                }
              </div>
            ) : null
          }
 
          {(pageOrigin === 'search' && searchTypeKey === 'style') && (
            <div
              className={`${styles.searchNameWrap} ${commonStyles.fw400} ${commonStyles.fs12}`}
              title={searchName}
            >
              搜索内容：
              <span className={commonStyles.fw400}>
                "
                {searchName}
                "
              </span>
            </div>
          )}
          {
            pageOrigin === 'active' && activeDetail && (
              (
                <div className={styles.activePageWrap}>
                  {!activeDetail?.customDetailUrl ? (<ActiveHeader store={store}/>) : (
                    <img
                      alt=""
                      className={styles.customDetailUrl}
                      src={activeDetail?.customDetailUrl}
                    />
                  )}
                </div>
              )
            )
          }
          {(pageOrigin === 'search' && searchTypeKey === 'style') && (
            <div className={styles.goodsFilterWrap}>
              <GoodsFilter
                cityId={cityId}
                clothesStyle={clothesStyle}
                ftCategoryIds={ftCategoryIds}
                marketIds={marketIds}
                onSearch={onSearch}
                parentCategoryId={parentCategoryId}
              />
            </div>
          )}
          {(pageOrigin === 'search' && searchTypeKey === 'style') && (
            <SordPanel
              currentSord={currentSord}
              loginStatus={loginStatus}
              store={sordPanelStore}
            />
          )}
 
          {(pageOrigin === 'active' || searchTypeKey === 'stall') && (
            <div className={`${styles.activeSearchOptions} ${ searchTypeKey === 'stall' ? styles.stallSearchOptions : ''}`}>
              <Filter store={searchTypeKey === 'stall' ? stallFilterItems : filterParams}/>
            </div>
          )}
           
          <div
            className={styles.chooseSquareSearchWrap}
            style={{
              height: dataList.length === 0 ? searchImg ? 'calc(100% - 380px)' : 'calc(100% - 580px)' : 'auto',
              margin: pageOrigin === 'active' ? '0 auto' : '12px auto',
            }}
          >
           
            <div
              className={`${styles.searchResult} ${pageOrigin === 'active' ? styles.searchResultActive : styles.searchResultBg}`}
            >
              {
                searchImg && searchImgUrl && searchTypeKey === 'style' && (
                  <div className={styles.searchImgWrap}>
                    <img
                      className={styles.searchImg}
                      src={searchImgUrl}
                    />
                    <span className={styles.searchImgTxt}>
                      搜索图片，找到以下相似款
                    </span>
                  </div>
                )
              }
              {dataList && dataList.length > 0 && (
                <div
                  className={styles.contentWrap}
                >
                  {searchTypeKey === 'style' && <StyleResult store={store}/>}
                  {searchTypeKey === 'stall' && <StallResult store={store}/>}
                </div>
              ) }
              {
                ((!dataList || dataList.length === 0) && !loading) && (
                  <div
                    className={styles.contentWrapEmpty}
                  >
                    <EmptyStatus
                      showTxt={searchImg ? '请到上面搜索吧' : ''}
                      style={{
                        width: 150,
                        height: 85,
                      }}
                      url={searchImg ? `${PUBLIC_IMAGE_URL}imageSearchIcon.png` : ''}
                    />
                  </div>
                )
              }
              {(dataList && dataList.length > 0 && !searchImg) && (
                <PaginationCus
                  pageSize={searchTypeKey === 'stall' ? 10 : 50}
                  store={paginationStore}
                  totalCount={totalCount}
                  totalPageCount={totalPageCount}
                />
              )}
            </div>
          </div>
          <RightSider store={rightSiderStore}/>
          <FooterLogo/>
          <Footer/>
        </Spin>
      </div>
    );
  }
}
 
