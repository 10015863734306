/**
 * 图片搜索
 */
import { message } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { PUBLIC_IMAGE_URL } from '../../../utils';
import { VIDEO_URL } from '../headerSearch/constant';
import styles from '../headerSearch/index.less';
import { IntroduceVideo } from '../headerSearch/introduceVideo';
import { IMG_URL } from './constant';
import imageStyles from './index.less';
import { SearchItem } from './searchItem';
import type { ImageSearchStore } from './store';

// 图搜页面-顶部
export const ImageSearch = observer((props: {
  store: ImageSearchStore;
  showTabs?: boolean;
  searchResult?: boolean;
  searchImg?: boolean;
  tabKey?: string;
  showTab?: boolean;
}) => {
  const { store, store: { operateVideoModal, videoVisible, refreshPage }} = props;

  // 获取粘贴板内容
  const getPasetInfo = (event) => {
    const items = (event.clipboardData?.items) || [];
    let file = null;
    if (items && items.length) {
      for (let i = 0; i < items.length; i++) {
        // 粘贴图片格式
        if (items[i].type.indexOf('image') !== -1) {
          file = items[i].getAsFile();
          store.beforeUpload(file);
          break;
        }

        // 粘贴文字
        if (items[i].type.indexOf('text/plain') !== -1) {
          items[i].getAsString((fileTxt) => {
            console.log(fileTxt, 'fileTxt');
            if (IMG_URL.test(fileTxt.split('?')[0])) {
              store.parent.searchImgUrl = fileTxt;
              store.parent.handleQuery({ page: 1 });
              store.toogleUploadPanel(false);
            } else {
              message.error('请输入正确的图片链接');
            }
          });
          break;
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('paste', getPasetInfo);
    return () => {
      window.removeEventListener('paste', getPasetInfo);
    };
  }, []);
  return (
    <>
      <div
        className={styles.headerMainWrap}
      >
        <div className={styles.header}>
          <div
            className={styles.headerLeft}
          >
            <img
              className={styles.shanHaiJinLogo}
              onClick={() => {
                refreshPage('/egenie-ts-vogue/homePage/index');
              }}
              src={`${PUBLIC_IMAGE_URL}shjLogo.png`}
            />
          </div>
          <div className={styles.headerCenter}>
            <SearchItem
              store={props.store}
            />
            <div className={styles.hotSearchs}>
              支持输入：复制图片链接或截图，粘贴（ctrl+v）到输入框
            </div>
          </div>
          <div
            className={`${styles.headerRight} ${imageStyles.headerRight}`}
            onClick={() => {
              operateVideoModal(true);
            }}
          >
            <img
              alt=""
              className={styles.cloudDesc}
              src={`${PUBLIC_IMAGE_URL}cloudDesc.gif`}
            />
          </div>
        </div>
      </div>
      <IntroduceVideo
        operateVideoModal={operateVideoModal}
        videoUrl={VIDEO_URL}
        videoVisible={videoVisible}
      />
    </>
  );
});

