import { Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { ProductCard } from '../components';
import styles from './index.less';
import type { Store } from './store';

@observer
export default class StyleResult extends React.Component<{ store: Store; }> {
  constructor(props) {
    super(props);
  }

  render(): JSX.Element {
    const { dataList, rightSiderStore, headerStore: { gotoPage }} = this.props.store;
    
    return (
      <div
        className={styles.styleListWrap}
      >
        <Row gutter={[
          12,
          12,
        ]}
        >
          {dataList.map((item, index) => {
            const { goodsId } = item;
            return (
              <Col key={goodsId}>
                <ProductCard
                  onClick={() => {
                    gotoPage(`/egenie-ts-vogue/goodsDetail/index?goodsId=${goodsId}`);
                  }}
                  {...item}
                  callback={() => {
                    rightSiderStore.getMyStyleWaitNum();
                  }}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }
}

