/**
 * 图片搜索 下拉面板
 */
import { Button, Input, Spin, Upload } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { PUBLIC_IMAGE_URL } from '../../../utils';
import styles from '../headerSearch/index.less';
import imageStyles from './index.less';
import type { ImageSearchStore } from './store';

const { Dragger } = Upload;

export const SearchItem = observer((props: { store: ImageSearchStore; }) => {
  const { store: { searchName, showUploadPanel, loading, changeInput, searchPro, beforeUpload, toogleUploadPanel }} = props;
  return (
    <div className={imageStyles.headerSearchWrap}>
      <Input
        className={` ${imageStyles.searchInput}`}
        onChange={changeInput}
        onPressEnter={searchPro}
        placeholder="输入图片链接或截图，粘贴（ctrl+v）到输入框"
        value={searchName}
      />
      <img
        alt=""
        className={imageStyles.uploadBtn}
        onMouseEnter={() => {
          toogleUploadPanel(true);
        }}
        src={`${PUBLIC_IMAGE_URL}uploadImg.png`}
      />
      {showUploadPanel && (
        <div className={`${imageStyles.uploadWrap} fade-enter fade-enter-active fade`}>
          <Spin spinning={loading}>

            <div className={imageStyles.selectFileBtnWrap}>
              <Dragger
                beforeUpload={beforeUpload}
                className="avatar-uploader"
                multiple={false}
                name="file"
                showUploadList={false}
              >
                <p className="ant-upload-hint">
                  拖拽图片到这里
                </p>
              </Dragger>
            </div>
            <div
              className={imageStyles.selectFileBtnWrap}
              style={{
                left: 127,
                top: 10,
              }}
            >
              <Upload
                beforeUpload={beforeUpload}
                className="avatar-uploader"
                listType="picture-card"
                name="avatar"
                showUploadList={false}
              >
                <Button
                  danger
                  type="primary"
                >
                  选择文件
                </Button>
              </Upload>
            </div>
            <div
              className={imageStyles.closeIcon}
              onClick={() => {
                toogleUploadPanel(false);
              }}
            >
              <img
                alt=""
                className={imageStyles.closePanel}
                src={`${PUBLIC_IMAGE_URL}closePanel.png`}
              />
            </div>
          </Spin>
        </div>
      )}
    </div>
  );
});
